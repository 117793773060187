// Oxygen regular
@font-face {
  font-family: 'Oxygen';
  font-style: auto;
  font-weight: normal;
  src: local('Oxygen'), local('Oxygen-Regular'), url('./assets/fonts/oxygen/Oxygen-Regular.ttf') format('truetype');
}

// Oxygen bold
@font-face {
  font-family: 'Oxygen';
  font-style: auto;
  font-weight: bold;
  src: local('Oxygen'), local('Oxygen-Bold'), url('./assets/fonts/oxygen/Oxygen-Bold.ttf') format('truetype');
}

// Oxygen light
@font-face {
  font-family: 'Oxygen';
  font-style: auto;
  font-weight: 300;
  src: local('Oxygen'), local('Oxygen-Light'), url('./assets/fonts/oxygen/Oxygen-Light.ttf') format('truetype');
}
