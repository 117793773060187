@mixin css-variables {
  :root {
    --portal-expanded: 1;
    // Colors
    --White: #FFF;
    --Off-White: #F8F9F9;
    --Black: #151414;
    --Orange: #C75000;
    --Orange-hover: #AE4600;
    --Orange-rgb: 199, 80, 0;
    --Green: #5AD1C6;
    --Blue-Dark: #39444E;
    --Blue-Darker: #222533;
    --Blue-Darker-Tone: #1F212E;
    --Neon-Blue: #5668FF;
    --Porcelain: #ECF0F1;
    --Grey-1: #D1D5D4;

    --Grey-3: #909090;
    --Border-Gray: #D3D3D6;
    --Error: #C75000;
    --Required-Sign: var(--Orange);

    // Filters
    --Flt-Orange: brightness(0) saturate(100%) invert(36%) sepia(32%) saturate(3208%) hue-rotate(4deg) brightness(90%) contrast(103%);
    --Flt-Red: brightness(0) invert(64%) sepia(67%) saturate(2616%) hue-rotate(309deg) brightness(95%) contrast(97%);
    --Flt-Error: brightness(0) saturate(100%) invert(36%) sepia(32%) saturate(3208%) hue-rotate(4deg) brightness(90%) contrast(103%);

    // Values
    --site-width: 1296px;
  }
}
