@use "variables" as *;

@mixin ui-checkbox {

  label:has(input[type="checkbox"]),
  label:has(input[type="radio"]) {
    cursor: pointer;
    user-select: none;
    font-weight: normal;
    font-family: $font;

    & > span {
      vertical-align: super;
      margin-left: 6px;
    }
  }

  label:has(input[type="radio"]) {
    & > span {
      display: inline-block;
      transform: translateY(3px);
    }
  }

  label:has(input[type="checkbox"]:checked),
  label:has(input[type="radio"]:checked) {
    font-weight: bold;
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: relative;
    width: 18px;
    max-width: 18px;
    height: 18px;
    color: var(--Black);
    border: 1.5px solid var(--Grey-1);
    border-radius: 2px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    padding: 0;
    transition-property: border-color;
    transition-duration: $hover-time;
    transition-timing-function: linear;
    will-change: border-color;
    flex-shrink: 0;
    flex-grow: 0;

    &::before {
      transition: opacity $hover-time linear;
      position: absolute;
      content: '';
      display: block;
      top: 0px;
      left: 5px;
      width: 4px;
      height: 10px;
      border-style: solid;
      border-color: var(--Orange);
      ;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:focus {
      border: 1.5px solid var(--Grey-1);
    }

    &:checked {
      border: 1.5px solid var(--Orange);

      &:focus {
        border: 1.5px solid var(--Orange);
      }

      &::before {
        opacity: 1;
      }
    }

    &:hover {
      border-color: var(--Black);
    }

    &+label {
      user-select: none;
      transform: translateY(1px);
      cursor: pointer;
    }

    &[disabled] {
      pointer-events: none;
      border: 1.5px solid var(--Grey-1);

      &:checked:before {
        border-color: var(--Grey-1);
      }

      //filter: grayscale(1);
    }
  }

  input[type="radio"] {
    border-radius: 100px;

    &::before {
      border: none;
      background: var(--Orange);
      width: 9px;
      height: 9px;
      border-radius: 100px;
      top: 3px;
      left: 3px;
    }
  }

  // Grid checkboxes
  input[type="checkbox"],
  input[type="radio"] {
    &.grid-checkbox {
      pointer-events: none;
      border: none;
      background-color: rgba(0, 0, 0, 0.04);

      &:before {
        top: 2px;
        left: 6px;
        width: 5px;
      }

      &:checked {
        border: none;
        filter: grayscale(1);

      }
    }
  }
}

// Validation for radio buttons inside required controls
.control.required:not(:has(:checked)) input[type="radio"].ng-touched {
  border-color: var(--Error);
}
