@use "txt" as *;

@mixin ui-input {
  input {
    @include txt-16;
    box-sizing: border-box;
    height: 40px;
    border-radius: 8px;
    border: 1px solid var(--Grey-1, #D1D5D4);
    background: var(--White, #FFF);
    padding: 10px 20px;
    outline: none;

    &::placeholder {
      color: var(--Grey-3);
    }

    &:focus {
      border: 1px solid var(--Black, #151414);
    }

    &[disabled] {
      background: var(--Off-White);
      //opacity: 0.7;
      color: var(--Grey-3);
      pointer-events: none;
    }
  }

  input[type="date"],  input[type="datetime-local"] {
    &.empty {
      color: var(--Grey-3);
    }
    &::-webkit-calendar-picker-indicator {
      font-size: 16px;
      cursor: pointer;
      color: transparent;
      background-color: transparent;
      content: " ";
      display: block;
      width: 16px;
      height: 16px;
      background-image: url('../../images/icon-calendar.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      /* }
    filter: var(--Flt-Orange);
   */
      //width: 0;
      //height: 0;
    }

    /*  &::-webkit-date-and-time-value {
    color: red;
  }*/
  }

  input[type=number] {
    padding-right: 18px;

    &::-webkit-outer-spin-button {
      filter: var(--Flt-Orange);
    }

    &::-webkit-inner-spin-button {
      height: 40px;
      background-color: transparent;
      cursor: pointer;
      //background: red;
      //padding-right: 10px;
      opacity: 0;
      // padding: 4px;
    }

    &:hover,
    &:focus {
      &::-webkit-inner-spin-button {
        opacity: 0.5;
      }
    }
  }
}
