@use "variables" as *;
@use "breakpoint" as *;

@mixin ui-portal {
  .ui-portal-title {
    display: flex;
    align-items: center;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--Grey-1);
    position: sticky;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 14;
    font-size: 24px;

    &>button, &>a {
      max-height: 32px;
      margin-left: 10px;

      &:first-of-type {
        margin-left: auto;
      }
    }

    &>.ui-portal-title-buttons {
      display: flex;
      gap: 10px;
      margin-left: auto;
      //max-height: 24px;
      overflow: visible;

      &>button, &>a {
        max-height: 32px;
      }
    }
  }

  .ui-portal-title+* {
    margin-top: 20px;
  }

  .ui-portal-grid-header {
    position: sticky;
    top: 0;
    left: 0;
    background: white;
    z-index: 12;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 16px;
    padding-bottom: 10px;

    &>h4 {
      font-weight: 400;
      margin-right: auto;
    }
  }

  .ui-portal-actions {
    //view-transition-name: portal-actions;
    //view-transition-name: disabled1;
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 960px;
    height: 80px;
    position: fixed;
    bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 31;
    background-color: var(--White);
    align-items: center;
    //box-shadow: 0 2px 10px #00000014;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background: linear-gradient(to top, #00000014, rgb(0 0 0 / 0%));
      top: -6px;
      left: 0;
    }

    @include breakpoint($scr-tablet) {
      --portal-collapsed: calc(1 - var(--portal-expanded));
      flex-wrap: wrap;
      height: auto;
      gap: 10px;
      width: calc(100% - 430px * var(--portal-expanded));
      padding-right: calc(110px * var(--portal-collapsed));
    }

    @include breakpoint($scr-mobile) {
      width: 100%;
      left: 0;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .ui-portal-columns {
    display: flex;
    gap: 24px;

    &>div:first-child {
      flex: 1 1 100%;
    }

    &>div:last-child {
      flex-shrink: 0;
      flex-grow: 0;
      width: 196px;
      flex-basis: 196px;
    }
  }

  .ui-portal-fixed {
    position: sticky;
    left: 0;
  }
}
