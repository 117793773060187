@use "txt" as *;
@use "variables" as *;

@mixin ui-link {
  .ui-link {
    -webkit-tap-highlight-color: transparent;
    font-family: $font;
    color: var(--Orange);
    text-decoration: none;
    transition: color $hover-time linear;
    will-change: color;
    cursor: pointer;

    &.text {
      color: var(--Black);
    }

    &:hover {
      color: var(--Orange-hover);
    }
  }
}
