@use "txt" as *;

@mixin icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;

  &.active {
    filter: var(--Flt-Orange);
  }
}

@mixin ui-icons {
  .ui-icon-unsaved {
    @include icon;
    background-image: url('../../images/icon-info.svg');
    cursor: default;
    vertical-align: middle;
    margin-left: 24px;
    padding-left: 20px;
    filter: var(--Flt-Error);
    user-select: none;
    @include txt-14;
    & {
      color: var(--Error);
      white-space: nowrap;
      width: auto;
      height: auto;
      background-position: left center;
      transform: translateY(-1px);
    }
  }

  .ui-icon-more {
    @include icon;
    width: 12px;
    height: 4px;
    background-image: url('../../images/more-horiz.svg');
  }

  .ui-icon-upload {
    @include icon;
    width: 17px;
    height: 16px;
    background-image: url('../../images/upload.svg');
  }

  .ui-icon-info {
    @include icon;
    cursor: help;
    background-image: url('../../images/icon-info.svg');
  }

  .ui-icon-arrow-down {
    @include icon;
    background-image: url('../../images/arrow-down-black.svg');
  }

  .ui-icon-plus {
    @include icon;
    background-image: url('../../images/btn-plus.svg');
  }

  .ui-icon-minus {
    @include icon;
    background-image: url('../../images/btn-minus.svg');
  }

  .ui-icon-settings {
    @include icon;
    background-image: url('../../images/system-uicons_settings.svg');
  }

  .ui-icon-search {
    @include icon;
    background-image: url('../../images/search.svg');
  }

  .ui-icon-search-gray {
    @include icon;
    background-image: url('../../images/search-gray.svg');
  }

  .ui-icon-close {
    @include icon;
    width: 20px;
    height: 20px;
    background-image: url('../../images/close.svg');
  }

  .ui-icon-menu-burger {
    @include icon;
    width: 30px;
    height: 30px;
    background-image: url('../../images/menu-burger.svg');
  }

  .ui-icon-menu-close {
    @include icon;
    width: 30px;
    height: 30px;
    background-image: url('../../images/menu-close.svg');
  }

  .ui-icon-search-blue {
    @include icon;
    width: 27px;
    height: 27px;
    background-image: url('../../images/search-blue.svg');
  }

  .ui-icon-edit {
    @include icon;
    background-image: url('../../images/icon-edit.svg');
  }

  .ui-icon-download {
    @include icon;
    background-image: url('../../images/icon-download.svg');
  }

  .ui-icon-filter {
    @include icon;
    width: 17px;
    background-image: url('../../images/icon-filter.svg');
  }

  .ui-icon-close-circle {
    @include icon;
    width: 24px;
    height: 24px;
    background-image: url('../../images/icon-circle-close.svg');
  }

  .ui-icon-close-circle-filled {
    @include icon;
    width: 24px;
    height: 24px;
    background-image: url('../../images/icon-circle-close-filled.svg');
  }

  .ui-icon-cross {
    @include icon;
    width: 14px;
    height: 14px;
    background-image: url('../../images/icon-cross.svg');
  }

  .ui-icon-location {
    @include icon;
    background-image: url('../../images/icon-location.svg');
  }

  .ui-icon-plus-gray {
    @include icon;
    width: 17px;
    background-image: url('../../images/icon-plus.svg');
  }

  .ui-icon-arrow-right {
    @include icon;
    width: 22px;
    height: 16px;
    background-image: url(../../images/arrow-right-orange.svg);
  }

  .ui-icon-drag-handle {
    @include icon;
    width: 16px;
    height: 16px;
    background-image: url(../../images/drag-handle.svg);
  }

  .ui-icon-subscribe {
    @include icon;
    background-image: url(../../images/icon-subscribe.svg);
  }

  .ui-icon-circle-more {
    @include icon;
    width: 20px;
    height: 20px;
    background-image: url(../../images/icon-circle-more.svg);
  }

  .ui-icon-reload {
    @include icon;
    width: 14px;
    height: 14px;
    background-image: url(../../images/icon-reload.svg);
  }

  .ui-icon-reorder {
    @include icon;
    width: 24px;
    height: 24px;
    background-image: url('../../images/icon-reorder.svg');
  }
}
